import React, { useState, useEffect } from 'react';
import './PropertiesUnderReview.css';
import styles from './PropertiesUnderReview.module.css'
import PropertiesUnderEyes from '../PropertiesUnderEyes/PropertiesUnderEyes';
import BaseUrl from '../Server/BaseUrl';

import {io} from 'socket.io-client';
 
// Connect to the Socket.IO server
const socket = io(BaseUrl.BaseUrl, {
  transports: ['websocket'],
}); 

function PropertiesUnderReview() {
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [deleteMessageVisible, setDeleteMessageVisible] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [userRows, setUserRows] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonText, setReasonText] = useState('');

  useEffect(() => {
    fetchData();

      // Listen for real-time updates from the server
      socket.on('newHostNotifications', (data) => {
        console.log('Real-time update received:', data);
        fetchData();  // Fetch data again to update the UI with new status
      });
  
      return () => {
        // Cleanup the socket connection when the component unmounts
        socket.off('newHostNotifications');
        socket.disconnect();
      };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/pending-properties`);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      setUserRows(data.reverse() || []);//added the logic to show latest entry first
    } catch (error) {
      console.error('Error fetching data:', error);
      setUserRows([]);
    }
  };
  console.log("under review property ", userRows)

  const handleAccept = async (user_id, property_id) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/accept_property`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id, property_id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      console.log('Accept response:', data);

      // Emit the property update event to notify other clients (e.g., host)
      console.log('Emitting event: propertyDecision');
      socket.emit('hostNotifications', { status: 'accept', user_id, property_id });

      setSuccessMessageVisible(true);
      fetchData();
      setTimeout(() => setSuccessMessageVisible(false), 3000); // Hide after 3 seconds
    } catch (error) {
      console.error('Error accepting property:', error);
    }
  };


  const handleCancel = (user_id, property_id) => {
    setSelectedUserId(user_id);
    setShowPopup(true);
    setSelectedPropertyId(property_id);
  }


  const handleRejectSubmit = async (e) => {
    e.preventDefault();

    // If no reason is selected, prevent submission
    if (!selectedReason) {
      alert("Please select a reason.");
      return;
    }

    if (!reasonText) {
      alert("Please give details for rejection.");
      return;
    }

    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/reject_property`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: selectedUserId,
          property_id: selectedPropertyId,
          reason: selectedReason,
          info: reasonText
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      console.log('Reject response:', data);

      // Emit the property update event to notify other clients (e.g., host)
      socket.emit('hostNotification', { status: 'cancel', user_id: selectedUserId, property_id: selectedPropertyId });

      setDeleteMessageVisible(true);
      fetchData();
      setTimeout(() => setDeleteMessageVisible(false), 3000); // Hide after 3 seconds

      setShowPopup(false);
      setSelectedPropertyId(null);
      setSelectedUserId(null);
      setSelectedReason('');
      setReasonText('');
    } catch (error) {
      console.error('Error rejecting property:', error);
    }
  };

  const handleReasonChange = (e) => {
    const value = e.target.value;
    setSelectedReason(value);
    if (value !== '5') {
      setReasonText('');
    }
  };

  const handleReasonTextChange = (e) => {
    setReasonText(e.target.value);
  };

  const handleFullScreen = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setShowFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
    setSelectedPropertyId(null);
  };

  const closePopup = () => {
    setShowPopup(false); // Close the popup
  };

  return (
    <div className="PropertiesUnderReviewcontainer">
      {/* Success and delete messages */}
      {successMessageVisible && (
        <div className="success-message">
          Request approved successfully!
        </div>
      )}

      {deleteMessageVisible && (
        <div className="delete-message">
          Declined successfully!
        </div>
      )}

      <h2 style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "30px", marginTop: "30px", textAlign: "left" }}>Properties Under Review</h2>
      {/* Table displaying properties under review */}
      {userRows.length > 0 ? (
        <table className="PropertiesUnderReview-Table">
          <thead>
            <tr className="PropertiesUnderReviewtr">
              <th className="PropertiesUnderReviewTH">S.No</th>
              <th className="PropertiesUnderReviewTH">Property Name</th>
              <th className="PropertiesUnderReviewTH">Price ($)</th>
              <th className="PropertiesUnderReviewTH">Country</th>
              <th className="PropertiesUnderReviewTH">Guest Count</th>
              <th className="PropertiesUnderReviewTH">Action</th>
            </tr>
          </thead>
          <tbody>
            {userRows.map((user, index) => (
              <tr key={user.property_id}>
                <td className="PropertiesUnderReviewTH">{index + 1}</td>
                <td className="PropertiesUnderReviewTH">{user.property_name}</td>
                <td className="PropertiesUnderReviewTH">{user.price_per_night}</td>
                <td className="PropertiesUnderReviewTH">{user.country}</td>
                <td className="PropertiesUnderReviewTH">{user.guest_count}</td>
                <td className="PropertiesUnderReviewTH">
                  <div className="PropertiesUnderReviewbutton">
                    <button style={{ color: "black" }} onClick={() => handleFullScreen(user.property_id)} title="View">
                      <i className="bi bi-eye-fill"></i>
                    </button>

                    <button style={{ color: "#05ec11" }} onClick={() => handleAccept(user.user_id, user.property_id)} title="accept">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </button>

                    <button style={{ color: "red" }} onClick={() => handleCancel(user.user_id, user.property_id)} title="cancel">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                    {/* <button style={{ color: "red" }}  onClick={handleButtonClick}
                    title="cancel">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-records-message" style={{ marginTop: "20px", textAlign: "center", fontSize: "18px" }}>

          No records found.
        </div>
      )}

      {/* Full screen overlay */}
      {showFullScreen && (
        <div className="full-screen-overlay">
          <div className="full-screen-content">
            <PropertiesUnderEyes propertyId={selectedPropertyId} close={handleCloseFullScreen} />
          </div>
        </div>
      )}

      {/* Popup logic */}
      {showPopup && (
        <div className={styles.popupContainer}>
          <h3>Reason for Declining</h3>
          <form onSubmit={handleRejectSubmit}>
            <select name="" className={styles.popupSelect} value={selectedReason} onChange={handleReasonChange}>
              <option value="Please Select A Reason">Please Select A Reason</option>
              <option value="Inappropriate Property Pictures">Inappropriate Property Pictures</option>
              <option value="Inappropriate Description">Inappropriate Description</option>
              <option value="Inappropriate Name/Profile Picture">Inappropriate Name/Profile Picture</option>
              <option value="Other's">Other's</option>
            </select>
            <textarea
              name="reasonText"
              className={styles.popupTextarea}
              cols="30"
              rows="5"
              placeholder="Please Specify Reason..."
              value={reasonText}
              onChange={handleReasonTextChange}
            />

            <div className={styles.buttonWrap}>
              <button onClick={closePopup} className={styles.closeBtn}>Close</button>
              <button type="submit" className={styles.saveBtn}>Save</button>
            </div>
          </form>
        </div>
      )}

      {/* Background overlay for the popup */}
      {showPopup && (
        <div
          onClick={closePopup}
          className={styles.popupOverlay}
        ></div>
      )}
    </div>
  );
}

export default PropertiesUnderReview;

