import React from 'react'
import "./BookingDetails.css"
import { useState, useEffect } from 'react';
import UserEditComponents from '../UserEditComponents/UserEditComponents';
import BookingById from '../BookingById/BookingById'
import BaseUrl from '../Server/BaseUrl';

function BookingDetails() {
  const [deleteMessageVisible, setDeleteMessageVisible] = useState(false);
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [component_name, setComponent_name] = useState();
  const [userRows, setUserRows] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;



  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/bookingInprogras`);
      const data = await response.json();
      const bookingsData = data.bookings;

      // Map the bookings to match the table format
      const formattedBookings = bookingsData.map((booking, index) => ({
        id: index + 1,
        user_id: booking.user_id,
        booking_id: booking.booking_id,
        name: booking.user_name, // Replace with actual name if available in data
        phone: booking.transaction_id, // Replace with actual phone if available in data
        email: booking.nights, // Replace with actual email if available in data
        Arrive: booking.entry_date,
        Depart: booking.exit_date,
        RoomType: booking.property_id, // Replace with actual room type if available
        Payment: booking.total_amount,
        status: booking.status,
        Action: ''
      }));

      // Update the state with formatted bookings
      setUserRows(formattedBookings);
      console.log('BOOKING DETAILS======>', bookingsData)
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };


  const handleDelete = (userId) => {
    // Implement delete logic here
    const updatedUsers = userRows.filter((user) => user.id !== userId);
    setUserRows(updatedUsers);

    // Show success message
    setDeleteMessageVisible(true);

    setTimeout(() => {
      setDeleteMessageVisible(false);
    }, 3000);


  };
  const handleEdit = (userId) => {
    // Find the user to edit based on the userId
    const user = userRows.find((user) => user.id === userId);
    setUserToEdit(user);
    setShowUserEdit(true);
  };
  const closeUserEdit = () => {
    setShowUserEdit(false);
  };


  const handleStatusUpdate = async (bookingId) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/statusUpdate/${bookingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 'Confirmed' }),
      })
      if (response.ok) {
        const updatedRows = userRows.filter((booking) => booking.booking_id !== bookingId);
        setUserRows(updatedRows);
        alert('Status updated successfully');
      } else {
        alert('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }

  }

  // Calculate the indices for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = userRows.slice(indexOfFirstItem, indexOfLastItem);
  console.log("current users data====>", currentUsers)

  // Calculate total pages
  const totalPages = Math.ceil(userRows.length / itemsPerPage);

  // Handle page change
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate page numbers for pagination
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }



  return (

    <div className="BookingDetailscontainer">

      <div style={{ position: 'relative' }}>
        {/* Your component content here */}
        {deleteMessageVisible && (
          <div
            style={{
              position: 'fixed',
              top: '25%',
              left: '67%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              color: 'red',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0px 0px 15px red',
            }}
          >
            Declined successfully!
          </div>
        )}



        <div>
          <div style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "30px", marginTop: "30px" }}>
            <span >Booking Details</span>



            {/* <button style={{marginLeft:"55.5%",justifyContent:"flex-end",alignItems:"flex-end",backgroundColor:"#f15a29",padding:"0px 20px",borderRadius:"10px",fontSize:"27px",border:"none"}} type="button">Add New</button> */}

          </div>




          <table className='BookingDetails-Table'>
            <thead>
              <tr className='BookingDetailstr'>
                <th className='BookingDetailsTH'>ID</th>
                <th className='BookingDetailsTH'>Name</th>
                <th className='BookingDetailsTH'>Transaction ID</th>
                <th className='BookingDetailsTH'>Nights</th>
                <th className='BookingDetailsTH'>Arrive</th>
                <th className='BookingDetailsTH'>Depart</th>
                <th className='BookingDetailsTH'>Payment</th>
                <th className='BookingDetailsTH'>Status</th>
                <th className='BookingDetailsTH'>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length > 0 ? (
                currentUsers.map((user) => (

                  <tr key={user.user_id}>
                    <td className='BookingDetailsTH'>{user.id}</td>
                    <td className='BookingDetailsTH'>{user.name}</td>
                    <td className='BookingDetailsTH'>{user.phone}</td>
                    <td className='BookingDetailsTH'>{user.email}</td>
                    <td className='BookingDetailsTH'>{user.Arrive}</td>
                    <td className='BookingDetailsTH'>{user.Depart}</td>
                    <td className='BookingDetailsTH'>{user.Payment}</td>
                    <td className='BookingDetailsTH'>{user.status === 'in Progress' ? (
                      <button
                        className="statusButton"
                        onClick={() => handleStatusUpdate(user.booking_id)}
                      >
                        Confirm
                      </button>
                    ) : (
                      user.status)}
                    </td>
                    <td className='BookingDetailsTH'>{user.Action}
                      <td className='BookingDetailsbutton' style={{ display: 'flex' }}>
                        {/* <button style={{ color: "black", background: "none", border: "none" }} title="View">
                          <i className="bi bi-eye-fill"></i>
                        </button> */}
                        <button style={{ color: "darkblue", border: "none", background: "none" }} onClick={() => handleEdit(user.id)} title='Edit'>
                          <i class="bi bi-pen" >
                            {/* ... your existing SVG code */}
                          </i>
                        </button>
                        <button style={{ color: "red", border: "none", background: "none" }} onClick={() => handleDelete(user.id)}>
                          <i class="bi bi-trash" aria-hidden="true" ></i>
                        </button>
                      </td>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className='BookingHistoryTD' colSpan="9" style={{ textAlign: 'center' }}>No bookings available.</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <div className="pagination-container">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Previous
            </button>

            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageClick(number)}
                className={`pagination-button ${currentPage === number ? 'active' : ''}`}
              >
                {number}
              </button>
            ))}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}








export default BookingDetails