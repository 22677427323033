import React, { createContext, useState, useContext, useEffect } from "react";
import BaseUrl from "../Components/Server/BaseUrl";

// Create a context
export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);


  // Fetch notifications (use your existing API here)
  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/notifications`);

      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }
        const data = await response.json();
        setUnreadCount(data.unreadCount);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <NotificationContext.Provider
      value={{ unreadCount, setUnreadCount ,fetchNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
