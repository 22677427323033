import React, { useState, useEffect } from 'react';
import "./Propertyowners.css";
import UserEditComponents from '../UserEditComponents/UserEditComponents';
import BaseUrl from '../Server/BaseUrl';
 
function Propertyowners() {
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [deleteMessageVisible, setDeleteMessageVisible] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userRows, setUserRows] = useState([]);

  // New states for confirmation modal
    const [showModal, setShowModal] = useState(false); // Line 13
    const [modalAction, setModalAction] = useState(""); // 'delete' or 'block' // Line 14
    const [selectedUser, setSelectedUser] = useState(null); // Line 15
 
  useEffect(() => {
    fetchData();
  }, []);
 
  const fetchData = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/all-users`);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      console.log('Fetched data:', data);
 
      // Filter users to only include those with at least one property in their propertylist
      const filteredData = data.filter(user => user.property_list && user.property_list.length > 0);
      console.log('filterdata', filteredData)
      setUserRows(filteredData.reverse()|| []);
      //added the logic to show latest entry first
       // Assuming your API returns an array of user data
    } catch (error) {
      console.error('Error fetching data:', error);
      setUserRows([]); // Setting userRows to an empty array or some default value
    }
  };


   // New: Show confirmation modal
 const openModal = (action, userId) => { // Line 55
  setModalAction(action);
  setSelectedUser(userId);
  setShowModal(true);
};

const closeModal = () => { // Line 60
  setShowModal(false);
  setModalAction("");
  setSelectedUser(null);
};

const confirmAction = async () => { // Line 65
  if (modalAction === "delete") {
    await handleDelete(selectedUser);
  } else if (modalAction === "block") {
    await handleBlock(selectedUser);
  }
  closeModal();
};
 
  const handleDelete = async (userId) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/users`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });
 
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete user");
      }
 
      const data = await response.json();
      console.log("User successfully deleted:", data);
 
      alert("The host has been deleted Successfully!!!");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the host.");
    }
  };
 
  const closeUserEdit = () => {
    setShowUserEdit(false);
  };
 
  const handleBlock = async (userId) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/users/block`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          blocked: true,
        }),
      });
 
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to block user");
      }
 
      const data = await response.json();
      console.log("User successfully blocked:", data);
 
      alert("Host has been successfully blocked!");
    } catch (error) {
      console.error("Error blocking user:", error);
      alert("An error occurred while blocking the user.");
    }
  };
 
  return (
    <div className="Propertyownerscontainer">
      <div style={{ position: 'relative' }}>
        {deleteMessageVisible && (
          <div
            style={{
              position: 'fixed',
              top: '26%',
              left: '67%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              color: 'red',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0px 0px 15px red',
            }}
          >
            Delete successfully!
          </div>
        )}
 
        <div>
          <div style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "30px", marginTop: "30px" }}>
            <span>Property Owners</span>
          </div>
 
          <table className='Propertyowners-Table'>
            <thead>
              <tr className='Propertyownerstr'>
                <th className='PropertyownersTH'>S.No</th>
                <th className='PropertyownersTH'>Name</th>
                <th className='PropertyownersTH'>Email</th>
                <th className='PropertyownersTH'>Country</th>
                <th className='PropertyownersTH'>Action</th>
              </tr>
            </thead>
            <tbody>
              {userRows.map((user, index) => (
                <tr key={index}>
                  <td className='PropertyownersTH'>{index + 1}</td>
                  <td className='PropertyownersTH'>{user.first_name}</td>
                  <td className='PropertyownersTH'>{user.email}</td>
                  <td className='PropertyownersTH'>{user.country}</td>
                  <td className='PropertyownersTH' style={{ display: 'flex' }}>
 
                    <div
                      className="RegisteredUserbutton"
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        style={{ color: "darkblue" }}
                        onClick={() => openModal("block", user._id)}
                        title="block"
                      >
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </button>
                      <button
                        style={{ color: "red" }}
                        onClick={() => openModal("delete", user._id)}
                        title="Delete"
                      >
                        <i className="bi bi-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
 
 
          {showUserEdit && (
            <div className="PropertyUserEditContainer">
              <UserEditComponents user={userToEdit} onClose={closeUserEdit} />
            </div>
          )}
        </div>
      </div>

      {/* New: Confirmation Modal */}
      {showModal && ( // Line 230
        <div className="modal-overlay">
          <div className="modal-content">
            <p>
              {modalAction === "delete"
                ? "Are you sure you want to delete this Host? This action can't be undone."
                : "Are you sure you want to block this Host? This action can't be undone."}
            </p>
            <div className="modal-buttons">
              <button onClick={confirmAction}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
 
export default Propertyowners;
 
 