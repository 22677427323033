import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaCheckDouble } from "react-icons/fa";
import SidebarComponents from "../SidebarComponents/SidebarComponents";
import BaseUrl from "../Server/BaseUrl";
import "./HeaderComponents.css";
import { NotificationContext } from "../../context/NotificationContext";
import { io } from "socket.io-client";

function HeaderComponents() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { unreadCount, setUnreadCount } = useContext(NotificationContext);
  const [notifications, setNotifications] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState(4); // Initial number of notifications to show
  const [activeTab, setActiveTab] = useState("Read"); // Active tab: Seen or Unseen
  const navigate = useNavigate();

  // Connect to the Socket.IO server
  const socket = io(BaseUrl.BaseUrl, {
    transports: ['websocket'],
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${BaseUrl.BaseUrl}/api/notifications`);

        if (!response.ok) {
          throw new Error("Failed to fetch notifications");
        }

        const data = await response.json();
        console.log("Data from header component", data.unreadCount);
        console.log("Data from header component", data.data);
        setNotifications(data?.data || []);
        setUnreadCount(data?.unreadCount || 0);
      } catch (error) {
        console.error("Error fetching notifications:", error.message);
      }
    };
    fetchNotifications();
  }, [setUnreadCount]);

  useEffect(() => {
    //Load notification sound
    const notificationSound = new Audio("/assets/Sound/notification_ding.mp3");

    // Listen for new notifications
    socket.on("newNotifications", (notification) => {
      console.log("New notification received:", notification);

      //play the notification sound
      notificationSound.play().catch((error) => {
        console.log("Error Playin notification sound :", error);
      });

      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
      setUnreadCount((prev) => prev + 1);  
    });

    // Cleanup on component unmount
    return () => {
      socket.off("newNotifications");
      socket.disconnect();
    };
  }, [setUnreadCount]);

  const fetchNotificationsByTab = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/notifications`);
      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }
      const data = await response.json();
      setNotifications(data?.data || []);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    fetchNotificationsByTab(); // Fetch data when switching tabs
  };

  //Function to close the sidebar
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const markAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/notifications`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ notification_id: notificationId }),
      });

      if (response.ok) {
        setNotifications((prevNotifications) =>
          prevNotifications.map((noti) =>
            noti._id === notificationId ? { ...noti, read: true } : noti
          )
        );
        setUnreadCount((prev) => Math.max(prev - 1, 0)); // Avoid negative counts
      }
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };

  // const markAllAsRead = async () => {
  //   try {
  //     const response = await fetch(
  //       `${BaseUrl.BaseUrl}/api/notifications/mark_all_read`, // The new API endpoint
  //       {
  //         method: "PATCH",
  //         headers: { "Content-Type": "application/json" },
  //       }
  //     );

  //     if (response.ok) {
  //       setNotifications((prevNotifications) =>
  //         prevNotifications.map((noti) => ({ ...noti, read: true }))
  //       );
  //       setUnreadCount(0); // Reset unread count to 0
  //     } else {
  //       console.error("Failed to mark all notifications as read.");
  //     }
  //   } catch (error) {
  //     console.error("Error marking all notifications as read:", error.message);
  //   }
  // };

  // Trigger navigation based on notification type
  const handleNotificationClick = (notification) => {
    switch (notification.type) {
      case "query":
        navigate("/QuieresComponents");
        break;

      case "property":
        navigate("/PropertiesUnderReview");
        break;

      case "withdrawal":
        navigate("/WithdrawlRequest");
        break;

      case "booking":
        if (notification.booking_status) {
          switch (notification.booking_status) {
            case "Completed":
              navigate("/BookingHistory");
              break;
            case "cancel by host":
              navigate("/CancelByHost");
              break;
            case "cancel by user":
              navigate("/CancelByUser");
              break;
          }
        }
        navigate("/BookingHistory");
        break;
    }
  };

  const loadMoreNotifications = () => {
    setVisibleNotifications((prev) => prev + 5);
  };

  const generateCustomMessage = (
    notifications,
    handleNotificationClick,
    visibleNotifications
  ) => {
    // Helper function to generate custom message for each notification
    const createCustomMessage = (notification) => {
      let customMessage = "";
      switch (notification.type) {
        case "property":
          customMessage = `A new property named "${notification.property_name}" has been added for approval by ${notification.name}.`;
          break;

        case "booking":
          if (notification.booking_status) {
            // Generate message based on booking_status
            switch (notification.booking_status) {
              case "Completed":
                customMessage = `Host has accepted the new booking.`;
                break;
              case "cancel by host":
                customMessage = `A booking has been cancelled by the host.`;
                break;
              case "cancel by user":
                customMessage = `A user has cancelled their booking.`;
                break;
            }
          } else {
            // Default message if booking_status is empty
            customMessage = `A new booking has been made by ${notification.name} for the property named "${notification.property_name}".`;
          }
          break;

        case "withdrawal":
          customMessage = `A new ${notification.withdrawal_type} withdrawal request has been made.`;
          break;

        case "query":
          customMessage = `You have a new query from ${notification.name}.`;
          break;
      }

      return customMessage;
    };

    return notifications.length === 0 ? (
      <p className="no-notifications">No notifications available</p>
    ) : (
      notifications.slice(0, visibleNotifications).map((noti, index) => (
        <React.Fragment key={index}>
          <div className="notification-div">
            <li
              className={`notification-message ${noti.read ? "read" : "unread"
                }`}
            >
              <a
                href="#"
                onClick={() => {
                  markAsRead(noti._id);
                  handleNotificationClick(noti);
                }}
              >
                <div className="media">
                  <span className="avatar avatar-sm">
                    <img
                      className="avatar-img rounded-circle"
                      alt="User"
                      src="/Image.png"
                    />
                  </span>
                  <div className="media-body">
                    <p
                      className={`noti-details ${noti.read ? "read" : "unread"
                        }`}
                    >
                      <span
                        className={`noti-title ${noti.read ? "read" : "unread"
                          }`}
                      >
                        {createCustomMessage(noti)}
                      </span>
                    </p>
                    <p className={`noti-time ${noti.read ? "read" : "unread"}`}>
                      <span className="notification-time">
                        {new Date(noti.createdAt).toLocaleString()}
                      </span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
          </div>
        </React.Fragment>
      ))
    );
  };

  const filteredNotifications =
    activeTab === "Read"
      ? notifications.filter((noti) => noti.read)
      : notifications.filter((noti) => !noti.read);

  return (
    <div>
      <div
        className="header"
        style={{ backgroundColor: "white", border: "none" }}
      >
        <div className="header-left">
          <Link to="/">
            {" "}
            <img
              className="logo1"
              style={{ height: "120px", marginLeft: "0", marginTop: "-18px" }}
              src="assets/img/CARIBBEANEAZE__2_-1-removebg-preview.png"
              alt="logo"
            />
          </Link>

          <a
            style={{ height: "200px", width: "200px" }}
            href="#"
            className="logo"
          >
            <span className="logoclass" style={{ color: "blue" }}></span>
          </a>
        </div>
        <a
          className="mobile_btn"
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-label="Toggle Sidebar"
        >
          <i className="fas fa-bars"></i>
        </a>
        <SidebarComponents
          sidebarOpen={sidebarOpen}
          closeSidebar={closeSidebar}
        />

        <ul className="nav user-menu">
          <li className="nav-item dropdown noti-dropdown">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <div className="bellring"> <i className={`fe fe-bell ${unreadCount > 0 ? "bell-animate" : ""}`}></i>
              </div>
              {unreadCount > 0 && <span className="badge badge-pill">{unreadCount}</span>}
            </a>
            <div
              className="dropdown-menu notifications"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
              </div>
              <div className="notification-tabs">
                <button
                  className={`tab-button ${
                    activeTab === "Read" ? "active" : ""
                  }`}
                  onClick={() => handleTabSwitch("Read")}
                >
                  Read
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "Unread" ? "active" : ""
                  } ${unreadCount > 0 ? "blinking-dot":""}`}
                  onClick={() => handleTabSwitch("Unread")}
                >
                  Unread
                </button>
              </div>
              <div className="noti-content">
                {generateCustomMessage(
                  filteredNotifications,
                  handleNotificationClick,
                  visibleNotifications
                )}
              </div>
              <div className="topnav-dropdown-footer">
                <a href="#" onClick={loadMoreNotifications}>
                  View all Notifications
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown has-arrow">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <span className="user-img">
                <img
                  className="rounded-circle"
                  src="assets/img/Group.png"
                  width="31"
                  alt="User"
                />
              </span>
            </a>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  <img
                    src="assets/img/Group.png"
                    alt="User"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="user-text">
                  <h6>Sher</h6>
                  <p className="text-muted mb-0">Owner</p>
                </div>
              </div>
              <a className="dropdown-item" href="#">
                My Profile
              </a>
              <a
                className="dropdown-item"
                href="login.html"
                onClick={() => {
                  localStorage.clear();
                }}
              >
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
      <SidebarComponents sidebarOpen={sidebarOpen} />
    </div>
  );
}

export default HeaderComponents;



