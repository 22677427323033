import React, { useState, useEffect } from "react";
import "./BlockedUser.css";
//import UserEditComponents from '../UserEditComponents/UserEditComponents';
import BaseUrl from "../Server/BaseUrl";
 
function BlockedUser() {
  const [userRows, setUserRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
 
  useEffect(() => {
    fetchData();
  }, []);
  const paginatedData = userRows.slice(
    (currentPage - 1) * itemsPerPage, // Start index
    currentPage * itemsPerPage
  );
 
  const fetchData = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/all-users`);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      console.log("Fetched data:", data);
 
      // Filter users to only include those with an empty propertylist
      const filteredData = data.filter(
        (user) => !user.property_list || user.property_list.length === 0 && user.blocked
      );
      console.log("filterdata", filteredData);
      setUserRows(filteredData.reverse() || []); // Assuming your API returns an array of user data
    } catch (error) {
      console.error("Error fetching data:", error);
      setUserRows([]); // Setting userRows to an empty array or some default value
    }
  };
  const totalPages = Math.ceil(userRows.length / itemsPerPage);
 
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
 
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  } 
 
  return (
    <div className="RegisteredUsercontainer">
      <div style={{ position: "relative" }}> 
        <div>
          <div
            style={{
              marginLeft: "20px",
              fontWeight: "bold",
              fontSize: "30px",
              marginTop: "30px",
            }}
          >
            <span>Blocked Users</span>
          </div>
 
          <table className="RegisteredUser-Table">
            <thead>
              <tr className="RegisteredUsertr">
                <th className="RegisteredUserTH">S.No</th>
                <th className="RegisteredUserTH">Name</th>
                <th className="RegisteredUserTH">Email</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((user, index) => (
                <tr key={user.id}>
                  <td className="RegisteredUserTH">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="RegisteredUserTH">
                    {user.first_name} {user.last_name}
                  </td>
                  <td className="RegisteredUserTH">{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Previous
            </button>
 
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageClick(number)}
                className={`pagination-button ${
                  currentPage === number ? "active" : ""
                }`}
              >
                {number}
              </button>
            ))}
 
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default BlockedUser;
 
 