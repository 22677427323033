import React, { useState, useEffect } from "react";
import "./WithdrawalRequest.css";
import BaseUrl from "../Server/BaseUrl";
import { emit,on,socket,disconnect } from "../Services/socket";

const WithdrawalRequest = () => {
  const [payouts, setPayouts] = useState([]);
  const [paypalWithdrawals, setPaypalWithdrawals] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState("paypal"); // Default method is PayPal
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    useEffect(() => {
      fetchData();
        // Listen for real-time updates from the server
        on('newHostNotifications', (data) => {
          console.log('Real-time update received:', data);
          fetchData();  // Fetch data again to update the UI with new status
        });
    
        return () => {
          // Cleanup the socket connection when the component unmounts
          socket.off('newHostNotifications');
          disconnect();
        };
    }, []);

  // Fetch general payout data (for Bank Transfer)
  useEffect(() => {
    fetchPayoutsData();
  }, []);

  // Fetch PayPal withdrawal data
  useEffect(() => {
    fetchPaypalWithdrawalsData();
  }, []);
  const fetchPayoutsData = () => {
    fetch(`${BaseUrl.BaseUrl}/api/payouts`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setPayouts(data.reverse() || []); // Set fetched data if array is not empty
        } else {
          setError("No Data Available");
        }
        setLoading(false); // Stop loading once data is fetched
      })
      .catch((err) => {
        setError("Error fetching payouts data");
        setLoading(false);
      });
  }
  

  const fetchPaypalWithdrawalsData = () => {
    fetch(`${BaseUrl.BaseUrl}/api/paypal-withdrawals`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setPaypalWithdrawals(data.reverse() || []); // Set PayPal withdrawal data if array is not empty
        } else {
          setError("No Data Available");
        }
        setLoading(false); // Stop loading once both data sets are fetched
      })
      .catch((err) => {
        setError("Error fetching PayPal withdrawal data");
        setLoading(false);
      });
  };

  const fetchData = () => {
    fetchPayoutsData();
    fetchPaypalWithdrawalsData();
  };

 

  // console.log("paypal request", paypalWithdrawals);

  const updateWithdrawalStatus = (id, status,amount) => {
    fetch(`${BaseUrl.BaseUrl}/api/paypal-withdrawals/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status ,amount }),
    })
      .then((response) => response.json())
      .then(() => {

        // Emit the notification event with data
          emit('hostNotification',{
          status,
          amount,
          read:false,
          created_at: Date.now(),
        })
        console.log("Notification paypal event emitted"); // Log to console
        // Refetch the data to make sure we have the latest status from the server
        fetch(`${BaseUrl.BaseUrl}/api/paypal-withdrawals`)
          .then((response) => response.json())
          .then((data) => {
            setPaypalWithdrawals(data); // Update the state with the latest data
          });
      })
      .catch((err) => {
        setError("Error updating status");
      });
  };

  // Function to update the withdrawal status
  const updateBankWithdrawalStatus = (id, status,amount) => {
    fetch(`${BaseUrl.BaseUrl}/api/payouts/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status, amount}), // Send new status to the server
    })
      .then((response) => response.json())
      .then(() => {
        // Refetch the data to make sure we have the latest status from the server
        fetch(`${BaseUrl.BaseUrl}/api/payouts`)
          .then((response) => response.json())
          .then((data) => {
            setPayouts(data); // Update the state with the latest data
          });
      })
      .catch((err) => {
        setError("Error updating status");
      });
  };

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value); // Update the selected method based on dropdown
  };

  if (loading) {
    return <div id="withdrawal-request-loading">Loading...</div>;
  }

  return (
    <div id="withdrawal-request-wrapper">
      <div id="withdrawal-request-container">
        <h2 id="withdrawal-request-title">Withdrawal Request Details</h2>

        {/* Dropdown to select payout method */}
        <div id="withdrawal-request-dropdown-container">
          <select
            id="withdrawal-request-dropdown"
            value={selectedMethod}
            onChange={handleMethodChange}
          >
            <option value="paypal">PayPal</option>
            <option value="bankTransfer">Bank Transfer</option>
          </select>
        </div>

        {/* Render PayPal Withdrawal Table if selected method is 'paypal' */}
        {selectedMethod === "paypal" && (
          <div id="withdrawal-request-table-container">
            <h3 id="withdrawal-request-highlight">PayPal Withdrawals</h3>

            {/* Scrollable table wrapper */}
            <div className="withdrawal-request-table-wrapper">
              {paypalWithdrawals.filter((paypal)=> paypal.status === "In Progress").length === 0 ? (
                <div className="withdrawal-request-table-wrapper">
                  <table id="withdrawal-request-table">
                    <thead>
                      <tr>
                        <th>PayPal Account</th>
                        <th>Transaction ID</th>
                        <th>Withdrawal Request Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                  <div style={{display:'flex' , alignItems:'center',justifyContent:'center',marginTop:'1rem', fontSize:'18px',fontWeight:'bolder'}}>No Records Found</div>
                </div>
              ) : (
                <table id="withdrawal-request-table">
                  <thead>
                    <tr>
                      <th>PayPal Account</th>
                      <th>Transaction ID</th>
                      <th>Request Amount </th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paypalWithdrawals.map(
                      (paypal) =>
                        paypal.status === "In Progress" && (
                          <tr key={paypal._id}>
                            <td>{paypal.paypalId}</td>
                            <td>{paypal._id}</td>
                            <td>${paypal.withdrawalAmount}</td>
                            <td>
                              {/* Dropdown for updating the status */}
                              <select
                                onChange={(e) =>
                                  updateWithdrawalStatus(
                                    paypal._id,
                                    e.target.value,
                                    paypal.withdrawalAmount,
                                  )
                                }
                              >
                                <option value="">{paypal.status}</option>
                                <option value="Paid">Paid</option>
                                <option value="Rejected">Rejected</option>
                              </select>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}

        {/* Render Bank Transfer Withdrawal Table if selected method is 'bankTransfer' */}
        {selectedMethod === "bankTransfer" && (
          <div id="withdrawal-request-table-container">
            <h3 id="withdrawal-request-highlight">Bank Transfer Withdrawals</h3>

            {/* Scrollable table wrapper */}
            <div className="withdrawal-request-table-wrapper">
              {payouts.filter((payouts)=> payouts.status === "In Progress").length === 0 ? (
                <div className="withdrawal-request-table-wrapper">
                  <table id="withdrawal-request-table">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Payout Option</th>
                        <th>Withdrawal Request Amount</th>
                        <th>Payout Method</th>
                        <th>Bank Details</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                  <div style={{display:'flex' , alignItems:'center',justifyContent:'center',marginTop:'1rem', fontSize:'18px',fontWeight:'bolder'}}>No Records Found</div>
                </div>
              ) : (
                <table id="withdrawal-request-table">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Payout Option</th>
                      <th>Request Amount</th>
                      <th>Payout Method</th>
                      <th>Bank Details</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payouts
                      .filter(
                        (payout) =>
                          payout.payoutMethod === "bankTransfer" ||
                          payout.payoutMethod === "westernUnion"
                      )
                      .map(
                        (payout) =>
                          payout.status === "In Progress" && (
                            <tr key={payout._id}>
                              <td>{payout.fullName}</td>
                              <td>{payout.email}</td>
                              <td>{payout.phone}</td>
                              <td>{payout.payoutOption}</td>
                              <td>${payout.withdrawalAmount}</td>
                              <td>{payout.payoutMethod}</td>
                              <td>
                                <div>
                                  {/* Bank Transfer Details */}
                                  {payout.payoutMethod === "bankTransfer" &&
                                    payout.bankTransferDetails && (
                                      <div>
                                        <p>
                                          <strong>Bank Name:</strong>{" "}
                                          {payout.bankTransferDetails.bankName}
                                        </p>
                                        <p>
                                          <strong>Account Number:</strong>{" "}
                                          {
                                            payout.bankTransferDetails
                                              .accountNumber
                                          }
                                        </p>
                                        <p>
                                          <strong>SWIFT Code:</strong>{" "}
                                          {payout.bankTransferDetails.swiftCode}
                                        </p>
                                        <p>
                                          <strong>IBAN:</strong>{" "}
                                          {payout.bankTransferDetails.iban}
                                        </p>
                                        <p>
                                          <strong>Routing Number:</strong>{" "}
                                          {
                                            payout.bankTransferDetails
                                              .routingNumber
                                          }
                                        </p>
                                      </div>
                                    )}

                                  {/* Western Union Details */}
                                  {payout.payoutMethod === "westernUnion" &&
                                    payout.westernUnionDetails && (
                                      <div>
                                        <p>
                                          <strong>Recipient Name:</strong>{" "}
                                          {
                                            payout.westernUnionDetails
                                              .recipientName
                                          }
                                        </p>
                                        <p>
                                          <strong>Recipient Address:</strong>{" "}
                                          {
                                            payout.westernUnionDetails
                                              .recipientAddress
                                          }
                                        </p>
                                        <p>
                                          <strong>Phone:</strong>{" "}
                                          {payout.westernUnionDetails.wuphone}
                                        </p>
                                        <p>
                                          <strong>Country:</strong>{" "}
                                          {payout.westernUnionDetails.country}
                                        </p>
                                      </div>
                                    )}
                                </div>
                              </td>
                              <td>
                                {/* Dropdown for updating the status */}
                                <select
                                  onChange={(e) =>
                                    updateBankWithdrawalStatus(
                                      payout._id,
                                      e.target.value,
                                      payout.withdrawalAmount
                                    )
                                  }
                                >
                                  <option value="">{payout.status}</option>
                                  <option value="Paid">Paid</option>
                                  <option value="Rejected">Rejected</option>
                                </select>
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WithdrawalRequest;
