import React, { useState, useEffect } from 'react';
import "./ReviewedProperties.css";
import UserEditComponents from '../UserEditComponents/UserEditComponents';
import PropertiesUnderEyes from '../PropertiesUnderEyes/PropertiesUnderEyes';
import BaseUrl from '../Server/BaseUrl';

function ReviewedProperties() {
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userRows, setUserRows] = useState([]);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/accepted-properties`);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      console.log('Fetched data:', data);
      setUserRows(data.reverse() || []);
      //added the logic to show latest entry first
      // Assuming your API returns an array of user data
    } catch (error) {
      console.error('Error fetching data:', error);
      setUserRows([]); // Setting userRows to an empty array or some default value
    }
  };


  const closeUserEdit = () => {
    setShowUserEdit(false);
  };

  const handleFullScreen = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setShowFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
    setSelectedPropertyId(null);
  };


  return (
    <div className="ReviewedPropertiescontainer">
      <div>
        <div style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "30px", marginTop: "30px" }}>
          <span>Reviewed Properties</span>
        </div>

        <table className='ReviewedProperties-Table'>
          <thead>
            <tr className='ReviewedPropertiestr'>
              <th className='ReviewedPropertiesTH'>S.No</th>
              <th className='ReviewedPropertiesTH'>Property Name</th>
              <th className='ReviewedPropertiesTH'>Price ($)</th>
              <th className='ReviewedPropertiesTH'>Country</th>
              <th className='ReviewedPropertiesTH'>Guest Count</th>
              <th className='ReviewedPropertiesTH'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userRows.map((user, index) => (
              <tr key={index}>
                <td className='ReviewedPropertiesTH'>{index + 1}</td>
                <td className='ReviewedPropertiesTH'>{user.property_name}</td>
                <td className='ReviewedPropertiesTH'>{user.price_per_night}</td>
                <td className='ReviewedPropertiesTH'>{user.country}</td>
                <td className='ReviewedPropertiesTH'>{user.guest_count}</td>
                <td className='ReviewedPropertiesTH ReviewedPropertiesTH-right'>
                  {user.Status}
                  <td className='ReviewedPropertiesbutton' style={{ display: 'flex',gap:"10px",justifyContent:'center' }}>
                    <button style={{ color: "black", background: "none", border: "none" }}
                      onClick={() => handleFullScreen(user.property_id)}
                      title="View">
                      <i className="bi bi-eye-fill"></i>
                    </button>

                    {/* <button style={{ color: "red", border: "none", background: "none" }}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button> */}
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>


        {showUserEdit && (
          <div className="ReviewedEditContainer">
            <UserEditComponents user={userToEdit} onClose={closeUserEdit} />
          </div>

        )}
        {showFullScreen && (
          <div className="full-screen-overlay">
            <div className="full-screen-content">
              <PropertiesUnderEyes propertyId={selectedPropertyId} close={handleCloseFullScreen} />
            </div>
          </div>
        )}


      </div>
    </div>
  );
}

export default ReviewedProperties;
