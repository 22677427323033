import { io } from "socket.io-client";
import BaseUrl from "../Server/BaseUrl";

// Connect to the Socket.IO server
const socket = io(BaseUrl.BaseUrl, { transports: ["websocket"] });

// Listen for events and handle them
const on = (event, callback) => {
  socket.on(event, callback);
};

// Emit events
const emit = (event, data) => {
  socket.emit(event, data);
};

// Disconnect the socket when no longer needed
const disconnect = () => {
  socket.disconnect();
};

export { socket, on, emit, disconnect };
