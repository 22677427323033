import React, { useState, useEffect } from "react";
import "./WithdrawalRequest.css";
import BaseUrl from "../Server/BaseUrl";
 
const CompletedRequest = () => {
  const [payouts, setPayouts] = useState([]);
  const [paypalWithdrawals, setPaypalWithdrawals] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState("paypal"); // Default method is PayPal
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  // Fetch general payout data (for Bank Transfer)
  useEffect(() => {
    fetch(`${BaseUrl.BaseUrl}/api/payouts`)
      .then((response) => response.json())
      .then((data) => {
        setPayouts(data.reverse() || []); // Set fetched data
        setLoading(false); // Stop loading once data is fetched
      })
      .catch((err) => {
        setError("Error fetching payouts data");
        setLoading(false);
      });
  }, []);
 
  // Fetch PayPal withdrawal data
  useEffect(() => {
    fetch(`${BaseUrl.BaseUrl}/api/paypal-withdrawals`)
      .then((response) => response.json())
      .then((data) => {
        setPaypalWithdrawals(data.reverse() || []); // Set PayPal withdrawal data
        setLoading(false); // Stop loading once both data sets are fetched
      })
      .catch((err) => {
        setError("Error fetching PayPal withdrawal data");
        setLoading(false);
      });
  }, []);
 
  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value); // Update the selected method based on dropdown
  };
 
  if (loading) {
    return <div id="withdrawal-request-loading">Loading...</div>;
  }
 
  const getStatusColor = (status) => {
    switch (status) {
      case "Paid":
        return "green";
      case "Rejected":
        return "red";
      default:
        return "gray";
    }
  };
 
  return (
    <div id="withdrawal-request-wrapper">
      <div id="withdrawal-request-container">
        <h2 id="withdrawal-request-title">Completed Request Details</h2>
 
        {/* Dropdown to select payout method */}
        <div id="withdrawal-request-dropdown-container">
          <select
            id="withdrawal-request-dropdown"
            value={selectedMethod}
            onChange={handleMethodChange}
          >
            <option value="paypal">PayPal</option>
            <option value="bankTransfer">Bank Transfer</option>
          </select>
        </div>
 
        {/* Render PayPal Withdrawal Table if selected method is 'paypal' */}
        {selectedMethod === "paypal" && (
          <div id="withdrawal-request-table-container">
            <h3 id="withdrawal-request-highlight">PayPal Withdrawals</h3>
 
            {/* Scrollable table wrapper */}
            <div className="withdrawal-request-table-wrapper">
              {paypalWithdrawals.filter((paypal)=> paypal.status !== "In Progress").length === 0 ? (
                <div className="withdrawal-request-table-wrapper">
                <table id="withdrawal-request-table">
                  <thead>
                    <tr>
                      <th>PayPal Account</th>
                      <th>Transaction ID</th>
                      <th>Withdrawal Request Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                </table>
                <div style={{display:'flex' , alignItems:'center',justifyContent:'center',marginTop:'1rem', fontSize:'18px',fontWeight:'bolder'}}>No Records Found</div>
              </div>
              ):(
                <table id="withdrawal-request-table">
                <thead>
                  <tr>
                    <th>PayPal Account</th>
                    <th>Transaction ID</th>
                    <th>Withdrawal Request Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paypalWithdrawals.map(
                    (paypal) =>
                      paypal.status !== "In Progress" && (
                        <tr key={paypal._id}>
                          <td>{paypal.paypalId}</td>
                          <td>{paypal._id}</td>
                          <td>${paypal.withdrawalAmount}</td>
                          <td
                            style={{
                              color: getStatusColor(paypal.status),
                              fontWeight: "bold",
                            }}
                          >
                            {paypal.status}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
              )}
            </div>
          </div>
        )}
 
        {/* Render Bank Transfer Withdrawal Table if selected method is 'bankTransfer' */}
        {selectedMethod === "bankTransfer" && (
          <div id="withdrawal-request-table-container">
            <h3 id="withdrawal-request-highlight">Bank Transfer Withdrawals</h3>
 
            {/* Scrollable table wrapper */}
            <div className="withdrawal-request-table-wrapper">
              {payouts.filter((payouts)=> payouts.status !== "In Progress").length === 0 ? (
                <div className="withdrawal-request-table-wrapper">
                <table id="withdrawal-request-table">
                  <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Withdrawal Request Amount</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                </table>
                <div style={{display:'flex' , alignItems:'center',justifyContent:'center',marginTop:'1rem', fontSize:'18px',fontWeight:'bolder'}}>No Records Found</div>
              </div>
              ):(
                <table id="withdrawal-request-table">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Withdrawal Request Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {payouts
                    .filter(
                      (payout) =>
                        payout.payoutMethod === "bankTransfer" ||
                        payout.payoutMethod === "westernUnion"
                    )
                    .map(
                      (payout) =>
                        payout.status !== "In Progress" && (
                          <tr key={payout._id}>
                            <td>{payout.fullName}</td>
                            <td>{payout.email}</td>
                            <td>{payout.phone}</td>
                            <td>${payout.withdrawalAmount}</td>
                            <td
                              style={{
                                color: getStatusColor(payout.status),
                                fontWeight: "bold",
                              }}
                            >
                              {payout.status}
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
 
export default CompletedRequest;
 
 