import React, { useState, useEffect } from 'react';
import "./DeclinedProperties.css";
import UserEditComponents from '../UserEditComponents/UserEditComponents';
import BaseUrl from '../Server/BaseUrl';

function DeclinedProperties() {
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userRows, setUserRows] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/rejected-properties`);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      console.log('Fetched data:', data);
      setUserRows(data.reverse() || []);
      //added the logic to show latest entry first
      // Assuming your API returns an array of user data
    } catch (error) {
      console.error('Error fetching data:', error);
      setUserRows([]); // Setting userRows to an empty array or some default value
    }
  };


  const closeUserEdit = () => {
    setShowUserEdit(false);
  };

  return (
    <div className="DeclinedPropertiescontainer">
      <div>
        <div style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "30px", marginTop: "30px" }}>
          <span>Declined Properties</span>
        </div>

        {userRows.length === 0 ? (
          <div style={{ marginTop: "20px", textAlign: "center", fontSize: "18px" }}>
            No Records Found
          </div>
        ) : (
          <table className='DeclinedProperties-Table'>
            <thead>
              <tr className='DeclinedPropertiestr'>
                <th className='DeclinedPropertiesTH'>S.No</th>
                <th className='DeclinedPropertiesTH'>Property Name</th>
                <th className='DeclinedPropertiesTH'>Price ($)</th>
                <th className='DeclinedPropertiesTH'>Country</th>
                <th className='DeclinedPropertiesTH'>Guest Count</th>
              </tr>
            </thead>
            <tbody>
              {userRows.map((user, index) => (
                <tr key={index}>
                  <td className='DeclinedPropertiesTH'>{index + 1}</td>
                  <td className='DeclinedPropertiesTH'>{user.property_name}</td>
                  <td className='DeclinedPropertiesTH'>{user.price_per_night}</td>
                  <td className='DeclinedPropertiesTH'>{user.country}</td>
                  <td className='DeclinedPropertiesTH'>{user.guest_count}</td>
                  <td className='DeclinedPropertiesbutton' style={{ display: 'flex' }}>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

      </div>
    </div>
  );
}

export default DeclinedProperties;


