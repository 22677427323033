import React, { useState, useEffect } from "react";
import "./RegisteredUser.css";
//import UserEditComponents from '../UserEditComponents/UserEditComponents';
import BaseUrl from "../Server/BaseUrl";
 
function RegisteredUser() {
  const [deleteMessageVisible, setDeleteMessageVisible] = useState(false);
  //const [showUserEdit, setShowUserEdit] = useState(false);
  //const [userToEdit, setUserToEdit] = useState(null);
  const [userRows, setUserRows] = useState([]);
 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // New states for confirmation modal
  const [showModal, setShowModal] = useState(false); // Line 13
  const [modalAction, setModalAction] = useState(""); // 'delete' or 'block' // Line 14
  const [selectedUser, setSelectedUser] = useState(null); // Line 15
 
  useEffect(() => {
    fetchData();
  }, []);
 
  const paginatedData = userRows.slice(
    (currentPage - 1) * itemsPerPage, // Start index
    currentPage * itemsPerPage
  );
 
  const fetchData = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/all-users`);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      console.log("Fetched data:", data);
 
      // Filter users to only include those with an empty propertylist
      const filteredData = data.filter(
        (user) => !user.property_list || user.property_list.length === 0 && !user.blocked
      );
      console.log("filterdata", filteredData);
      setUserRows(filteredData.reverse() || []);
      //added the logic to show latest entry first
      // Assuming your API returns an array of user data
    } catch (error) {
      console.error("Error fetching data:", error);
      setUserRows([]); // Setting userRows to an empty array or some default value
    }
  };
  const totalPages = Math.ceil(userRows.length / itemsPerPage);
 
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
 
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }


 // New: Show confirmation modal
 const openModal = (action, userId) => { // Line 55
  setModalAction(action);
  setSelectedUser(userId);
  setShowModal(true);
};

const closeModal = () => { // Line 60
  setShowModal(false);
  setModalAction("");
  setSelectedUser(null);
};

const confirmAction = async () => { // Line 65
  if (modalAction === "delete") {
    await handleDelete(selectedUser);
  } else if (modalAction === "block") {
    await handleBlock(selectedUser);
  }
  closeModal();
};


  const handleDelete = async (userId) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/users`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });
 
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete user");
      }
 
      const data = await response.json();
      console.log("User successfully deleted:", data);
 
      alert("The User Has Been Deleted Successfully!!!");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    }
  };
 
 
  const handleBlock = async (userId) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/users/block`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          blocked: true,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to block user");
      }

      const data = await response.json();
      console.log("User successfully blocked:", data);

      alert("User has been successfully blocked!");
    } catch (error) {
      console.error("Error blocking user:", error);
      alert("An error occurred while blocking the user.");
    }
  };
 
  // const handleEdit = (userId) => {
  //   const user = userRows.find((user) => user.user_id === userId);
  //   setUserToEdit(user);
  //   //setShowUserEdit(true);
  //   console.log(userId);
 
  // };
 
  // const closeUserEdit = () => {
  //   setShowUserEdit(false);
  // };
 
  return (
    <div className="RegisteredUsercontainer">
      <div style={{ position: "relative" }}>
        {deleteMessageVisible && (
          <div
            style={{
              position: "fixed",
              top: "26%",
              left: "67%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              color: "red",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0px 0px 15px red",
            }}
          >
            Delete successfully!
          </div>
        )}
 
        <div>
          <div
            style={{
              marginLeft: "20px",
              fontWeight: "bold",
              fontSize: "30px",
              marginTop: "30px",
            }}
          >
            <span>Registered User</span>
          </div>
 
          <table className="RegisteredUser-Table">
            <thead>
              <tr className="RegisteredUsertr">
                <th className="RegisteredUserTH">S.No</th>
                <th className="RegisteredUserTH">Name</th>
                <th className="RegisteredUserTH">Email</th>
                <th className="RegisteredUserTH">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* {userRows.map((user, index) => (
                <tr key={index}>
                  <td className='RegisteredUserTH'>{index + 1}</td>
                  <td className='RegisteredUserTH'>{user.first_name} {user.last_name}</td>
                  <td className='RegisteredUserTH'>{user.email}</td>
                  <td className='RegisteredUserTH'>{user.date}</td>
 
                  <td className='RegisteredUserTH'>
                    <div className='RegisteredUserbutton' style={{ display: 'flex' }}>
                      <button style={{ color: "darkblue" }} onClick={() => handleEdit(user.id)} title='block'>
                      <i className="bi bi-x-circle-fill"></i>
                      </button>
                      <button style={{ color: "red" }} onClick={() => handleDelete(user.id)} title='Delete'>
                        <i className="bi bi-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))} */}
              {paginatedData.map((user, index) => (
                <tr key={user.id}>
                  <td className="RegisteredUserTH">
                  {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="RegisteredUserTH">
                    {user.first_name} {user.last_name}
                  </td>
                  <td className="RegisteredUserTH">{user.email}</td>
 
                  <td className="RegisteredUserTH">
                    <div
                      className="RegisteredUserbutton"
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        style={{ color: "darkblue" }}
                        onClick={() => openModal("block", user._id)} // Line 198
                        title="block"
                      >
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </button>
                      <button
                        style={{ color: "red" }}
                        onClick={() => openModal("delete", user._id)} // Line 203
                        title="Delete"
                      >
                        <i className="bi bi-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Previous
            </button>
 
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageClick(number)}
                className={`pagination-button ${currentPage === number ? "active" : ""
                  }`}
              >
                {number}
              </button>
            ))}
 
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
          {/* {showUserEdit && (
            <div className="RegisteredUserEditContainer">
              <UserEditComponents user={userToEdit} onClose={closeUserEdit} />
            </div>
          )} */}
        </div>
      </div>
      {/* New: Confirmation Modal */}
      {showModal && ( // Line 230
        <div className="modal-overlay">
          <div className="modal-content">
            <p>
              {modalAction === "delete"
                ? "Are you sure you want to delete this user? This action can't be undone."
                : "Are you sure you want to block this user? This action can't be undone."}
            </p>
            <div className="modal-buttons">
              <button onClick={confirmAction}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}
 
export default RegisteredUser;
 
 
 